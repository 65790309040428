import { AppMessages } from 'i18n/messages';

export const LINKS: { name: string; translationKey: keyof typeof AppMessages; url: string }[] = [
  {
    name: 'Terms',
    translationKey: 'footer.menu.links.terms',
    url: process.env.NEXT_PUBLIC_TERMS_URL,
  },
  {
    name: 'Privacy',
    translationKey: 'footer.menu.links.privacy',
    url: process.env.NEXT_PUBLIC_POLICY_URL,
  },
  {
    name: 'SLA',
    translationKey: 'footer.menu.links.sla',
    url: process.env.NEXT_PUBLIC_SLA_URL,
  },
];
